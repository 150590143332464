import { configureRefreshFetch } from 'refresh-fetch'
import { get, multiPut } from 'lib/storage'

/* const version = require('../version.json') */
const server = process.env.REACT_APP_BACKEND_URL

export function clone(obj) {
	if (obj) {
		return JSON.parse(JSON.stringify(obj))
	} else {
		return obj
	}
}

function needsRefresh(error) {
	return error.status === 401
}

function convertToQueryString(obj) {
	if (window.cordova) {
		return obj
	}

	let output = []

	for (let k in obj) {
		output.push(`${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
	}

	return output.join('&')
}

function doRefresh() {
	return get('RefreshToken').then(function (refreshToken) {
		return fetchJSON(`${server}/users/reauth`, {
			method: 'POST',
			headers: {
				//'Origin': origin,
				'Access-Token': refreshToken,
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
				'x-deployment-id': process.env.VERCEL_DEPLOYMENT_ID || 'null',
			},
		}).then((res) => {
			const access = ['AuthToken', res.body.access]
			const refresh = ['RefreshToken', res.body.refresh]
			const profile = ['Profile', res.body.profile || {}]

			const args = [access, refresh]
			if (profile) {
				args.push(profile)
			}
			return multiPut(args)
		})
	})
}

const allowedEndpoints = [
	'/users/auth',
	'/users/reauth',
	'/password/reset',
	'/register/'
]

function isAllowedEndpoint(url) {
	return allowedEndpoints.some((test) => {
		return url.indexOf(test) > -1
	})
}

function doPost(url, data) {
	return get('AuthToken').then(function (authToken) {
		if (authToken || isAllowedEndpoint(url)) {
			return fetchJSON(`${server}${url}`, {
				method: 'POST',
				headers: {
					'Access-Token': authToken,
					'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
					'x-deployment-id': (process.env.VERCEL_SKEW_PROTECTION_ENABLED === '1' ? process.env.VERCEL_DEPLOYMENT_ID : ''),
				},
				body: convertToQueryString(data),
			}).then((res) => {
				return res.body
			})
		} else {
			return null
		}
	})
}

export const fetchapi = configureRefreshFetch({
	shouldRefreshToken: needsRefresh,
	refreshToken: doRefresh,
	fetch: doPost,
})

function doUpload(url, formData) {
	return get('AuthToken').then(function (authToken) {
		if (authToken || isAllowedEndpoint(url)) {
			return fetch(`${server}${url}`, {
				method: 'POST',
				headers: {
					'Access-Token': authToken,
					'x-deployment-id': (process.env.VERCEL_SKEW_PROTECTION_ENABLED === '1' ? process.env.VERCEL_DEPLOYMENT_ID : ''),
				},
				body: formData,
			}).then((response) => {
				return getResponseBody(response).then((body) => ({
					response,
					body,
				}))
			}).then(checkStatus).then(function ({ body }) {
				return body
			})
		}
	})
}

export const upload = configureRefreshFetch({
	shouldRefreshToken: needsRefresh,
	refreshToken: doRefresh,
	fetch: doUpload,
})

// from refresh-fetch/src/fetchJSON.js
const fetchJSON = (url, options) => {
	if (window.cordova) {
		return _fetch_cordova(url, options).then(function (response) {
			return getResponseBody(response).then(function (body) {
				return {
					response: response,
					body: body
				}
			})
		}).then(checkStatus)
	} else {
		return fetch(url, options).then(function (response) {
			return getResponseBody(response).then(function (body) {
				return {
					response: response,
					body: body
				}
			})
		}).then(checkStatus)
	}
}

const _fetch_cordova = (url, options) => {
	options.data = (options?.body ? Object.assign({}, options.body) : {})
	options.serializer = 'urlencoded'

	return new Promise((resolve, reject) => {
		window.cordova.plugin?.http?.sendRequest(
			url,
			options,
			(res) => {
				resolve({
					status: res.status,
					ok: true,
					url: url,
					headers: res.headers,
					body: res.data
				})
			},
			(res) => {
				// error
				try {
					reject({
						status: res.status,
						ok: false,
						error: res.error,
						headers: res.headers,
						url: res.url
					})
				} catch (err) {
					reject(reject({
						status: 500,
						ok: false,
						error: err.message,
						headers: {},
						url: url
					}))
				}
			}
		)
	})
}

const getResponseBody = (response) => {
	const contentType = response.headers.get('content-type')
	return contentType && contentType.indexOf('json') >= 0
		? response.clone().text().then(tryParseJSON)
		: response.clone().text()
}

const tryParseJSON = (json) => {
	if (!json) {
		return null
	}

	try {
		return JSON.parse(json)
	} catch (e) {
		throw new Error(`Failed to parse unexpected JSON response: ${json}`)
	}
}

function ResponseError(status, response, body) {
	this.name = 'ResponseError'
	this.status = status
	this.response = response
	this.body = body
}

ResponseError.prototype = Error.prototype

const checkStatus = ({ response, body }) => {
	if (response.ok) {
		return { response, body }
	} else {
		throw new ResponseError(response.status, response, body)
	}
}
